<!--***************************Company information top box (sme-data )***************************-->
<div class="fade-in">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12" style="width: 99.7%; margin-right: 3px;">
      <app-sme-data></app-sme-data>
    </div>
  </div>

  <br>

  <!--***************************Members information main box***************************-->
  <div class="row members-container">
    <div class="col-lg-12 members-header">
      <span class="header-content"> 
        <i class="mdi mdi-account-details"></i>
        <span class="header-content-title">
          تفاصيل الأعضاء
        </span>
      </span>
    </div>
    <div class="members-body row" >

    <!--***************************Add Member section ***************************-->
    <div class="col-lg-4 col-md-12 col-sm-12" >
        
        <!-- (1) Add member by Excel file (accordion system)-->
        <div class="accordion-item rounded" *ngIf="memberDataList != undefined && memberDataList.length>0" >
          <!-- accordion header -->
          <div class="accordion-header" id="headingExcel" >
              <button class="accordion-button collapsed  border-1 rounded"
                [ngClass]="{'border-0': true, 'rounded-all': isCollapsed}"
                type="button" 
                (click)="toggleCollapse()"
                data-bs-toggle="collapse" 
                data-bs-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
              >
                
                <div class="btn-content">
                  <div class="right-site-btn-content">
                    <img class="excel-icon" src="/assets/images/icon/excel-closed.png">
                      <span class="btn-title">ارفاق ملف اكسل</span>
                  </div>
                  <div class="left-site-btn-content">
                    <i class="mdi mdi-chevron-down" *ngIf="isCollapsed"></i>
                    <i class="mdi mdi-chevron-up" *ngIf="!isCollapsed"></i>
                  </div>
                </div>
              </button>
          </div>

          <!-- accordion body -->
          <div id="collapseOne"
              class="accordion-collapse border-0 collapse" 
              aria-labelledby="headingExcel"
              data-bs-parent="#buyingquestion">

              <div class="accordion-body">
                  <app-member-file ></app-member-file>
              </div>

          </div>
      </div>
    
      <br>
    
      <!-- (2) Add member with form system -->
      <app-member 
        [parentMemberDataList]="memberDataList" 
        (openFormChange)="handleOpenFormChange($event)" 
        (newAddedMember)="onMemberAdd($event)" >
      </app-member>
    
    </div> <!-- End col-lg-4-->
     

    <!--***************************Members List Section ***************************-->
    <div class="col-lg-8 col-md-12 col-sm-12 " >

      <div class="col-12 ">
        <div class="component-wrapper rounded shadow">
          
          <!--  MEMBER SEARCH BAR -->
            <div class="p-4 ms-1 border-bottom" *ngIf="memberDataList != undefined && memberDataList.length>0">
              
              <ng-container >  
                <div class="row seach-system-grid" >
                  <div class="col-lg-9 col-md-9 col-sm-12">
                    <div class="form-row">
                      
                      <div class="form-icon mb-2">
                        <input
                          [(ngModel)]="searchInput"
                          (ngModelChange)="applyFilter($event)"
                          class="form-control ps-5"
                          #idNoInput
                          placeholder="البحث مثال : صالح.." 
                          type="text"
                        />
                        <span class="svg-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16" >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
                          </svg>
                        </span>
                        <!--icon for clear search-->
                        <span class="svg-icon-clear" (click)="clearFilter()" >
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-octagon-fill" viewBox="0 0 16 16" style="color:rgb(218, 128, 128)">
                            <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353zm-6.106 4.5L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708"/>
                          </svg>
                      </span>
                      </div>  
                    </div>
                  </div> <!--end search-bar col -->
                  <div class="col-lg-3 col-md-3 col-sm-12">
                    <button class="btn btn-info small text-nowrap"  (click)="saveMemberDisclosures()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy-fill" viewBox="0 0 16 16">
                            <path d="M0 1.5A1.5 1.5 0 0 1 1.5 0H3v5.5A1.5 1.5 0 0 0 4.5 7h7A1.5 1.5 0 0 0 13 5.5V0h.086a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5H14v-5.5A1.5 1.5 0 0 0 12.5 9h-9A1.5 1.5 0 0 0 2 10.5V16h-.5A1.5 1.5 0 0 1 0 14.5z"/>
                            <path d="M3 16h10v-5.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5zm9-16H4v5.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5zM9 1h2v4H9z"/>
                          </svg>
                        <span  class="ms-2 p-2">حفظ البيانات</span>
                    </button>
                  </div>
                </div> <!-- End seach row -->
              </ng-container>
            </div> <!-- End Search bar memeber-->
            

            <!--Bootstrap Table-->
            <div class="p-4 bg-light" *ngIf="memberDataList != undefined && memberDataList.length>0">
                <div class="bg-white shadow border-1 rounded">
                    <table class="table ">

                      <!-- HEADER TABLE (TH) -->
                      <thead>
                        <th *ngFor="let column of columnsToDisplayNames" class="th-element"
                            [style.width]="column.width">
                          <div class="header-content" style="display: flex; align-items: center; justify-content: center;">
                            <span class="th-element-title" [innerHTML]="trans(column.label)"></span>
                          </div>
                        </th>
                      </thead> 
                      
                      <!-- BODY TABLE (TR) -->
                      <tbody style="z-index: 15;">
                        <ng-container *ngFor="let element of paginatedData; let i = index" [class.table-active]="expandedElementDisclosure === element" >
                          
                          <!-- BADGE (Colored line of member info) -->
                          <tr *ngIf="element.sponsorIdNo===null"   class="minimized-row" > 
                              <td *ngFor="let column of columnsToDisplayNames" class="colored-table-element"
                                [style.width]="column.width"
                                [ngClass]="{'opacity-50': !isValidMemberStatus().includes(element.yakeenCheckStatus)}">

                                <img 
                                  *ngIf="column.infoElem(element).tag=='img'" 
                                  [src]="column.infoElem(element).value" 
                                  class="{{column.infoElem(element).class}}" 
                                  alt="gender-icon"
                                >

                                <span *ngIf="column.infoElem(element).tag=='badge'"  
                                  class="{{column.infoElem(element).class}}"> 
                                  {{column.infoElem(element).value}}
                                </span>
                              </td>
                          </tr><!--END BADGE -->


                          <!-- TR (members info line with action) -->
                          <tr *ngIf="element.sponsorIdNo===null">
                            <td *ngFor="let column of columnsToDisplayNames;" class="td-container info-line" 
                              [style.width]="column.width"
                              [ngClass]="{'opacity-50': !isValidMemberStatus().includes(element.yakeenCheckStatus)}">
                                
                              <!-- Member data value -->
                              <ng-container *ngIf="column.key !== 'showMemberDisclosure' && column.key !== 'showDependents' && column.key !== 'actions';">
                                <div class="td-value">
                                  <span class="td-value" *ngIf="column.key!='id'">{{trans(element[column.key])}}</span>
                                  <span class="td-value" *ngIf="column.key=='id'" >
                                    <img src="/assets/images/masarat/member/{{getImgDir(element.nationality)}}/{{element.relation.toLowerCase()}}-{{element.gender.toLowerCase()}}.png"
                                         class="gender-img" 
                                    />
                                  </span>
                                </div>
                              </ng-container>
                                  
                              <!-- Member Disclosure DropDown Icon -->
                              <ng-container *ngIf="column.key == 'showMemberDisclosure' && isValidMemberStatus().includes(element.yakeenCheckStatus)">
                                <div (click)="toggleExpandedDisclosure(element, i)"  class="toggle-element">
                                  <a  class="btn btn-icon btn-pills btn-outline-primary toggle-orange">
                                    <mat-icon  
                                      [ngClass]="{'expanded':(expandedElementDisclosure === element) && !isclosing }"
                                      class="toggle-icon toggle-icon-orange" >
                                      {{expandedElementDisclosure === element ? 'keyboard_arrow_upward' :
                                        'keyboard_arrow_down'}}
                                    </mat-icon>  
                                  </a>
                                </div>
                              </ng-container>

                              <!--  Sub Memeber DropDown Icon -->
                              <ng-container *ngIf="column.key === 'showDependents' && isValidMemberStatus().includes(element.yakeenCheckStatus)">
                                <div (click)="toggleExpandedElementMember(element , i)"  class="toggle-element">
                                  <a 

                                  class="btn btn-icon btn-pills btn-outline-info toggle-green"  *ngIf="getDependentsForSponsorMember(element).data.length>0">
                                    <mat-icon
                                    [ngClass]="{'expanded':(expandedElementMember === element) && !isclosingMember }"
                                    class="toggle-icon toggle-icon-green"
                                    style="color:#026a7c; cursor: pointer;" >{{expandedElementMember === element ? 'keyboard_arrow_upward' :
                                      'keyboard_arrow_down'}}
                                      </mat-icon> 
                                  </a>
                                </div>
                              </ng-container>

                              <!-- action -->
                              <div class="btn-group dropdown-primary"  *ngIf="column.key === 'actions' && (memberOnAction!==element)" style="cursor: pointer;">
                                <mat-icon data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >more_vert</mat-icon>
                                <div class="dropdown-menu">
                                    <a  class="dropdown-item" (click)="editMember(element)">تعديل</a>
                                    <div class="dropdown-divider"></div>
                                    <a  class="dropdown-item" (click)="deleteMember(element)">حذف</a>
                                    <div class="dropdown-divider"></div>
                                    <a  class="dropdown-item" (click)="addDependentMember(element)">إضافة تابع</a>
                      
                                </div>
                              </div>

                              <!-- Cancel action -->
                              <div *ngIf="(memberOnAction===element) && column.key === 'actions'">
                                <a (click)="cancelActionMember()"  class="cancel-btn cancel-fix-btn">
                                  <div>
                                    <b style="cursor: pointer;">الغاء</b>
                                    <mat-icon style="margin-right:10px; cursor :pointer">arrow_forward</mat-icon>
                                  </div>
                                </a>
                              </div>

                              <ng-template #showMemberDisclosure>
                                <ng-container *ngIf="column.key !== 'showDependents'; ">
                                  <!--<th mat-header-cell *matHeaderCellDef>{{columnsToDisplayNames[column]}}</th>-->
                                  <th ><mat-icon   style="cursor: pointer;">assignment_add</mat-icon></th>
                                  <td mat-cell *matCellDef="let element" (click)="toggleExpandedDisclosure(element, element.id)" >
                                    <mat-icon    style="cursor: pointer;"> {{expandedElementDisclosure === element ? 'arrow_upward' :
                                      'keyboard_arrow_down'}}</mat-icon> 
                                  </td>
                                </ng-container>
                              </ng-template>
                            </td>
                          </tr> <!-- END Members info line with action-->

                          <!-- JUST A BLOCK TO CALL FUNCTION SETDISCLOSURESTATS-->
                          <!--<app-member-disclosure style="display: none;" 
                            (disclosureStats)="setDisclosureStats($event)"
                            [memberId]="element.id"
                            [disclosure]="disclosureList"
                          ></app-member-disclosure>-->
                          <!-- END A BLOCK TO CALL FUNCTION SETDISCLOSURESTATS-->


                          <!-- EXPANDED Line 1 Disclosure -->
                          <tr>
                            <td [attr.colspan]="columnsToDisplayNames.length" class="collapsed-td"
                                [class.hidden-tr]="expandedElementDisclosure !== element" >
                                <!-- Expanded Content Here -->
                                <div 
                                    [@detailExpand]="(element === expandedElementDisclosure && i === currentExpandedDisclosureIndex) ? 'expanded' : 'collapsed'"
                                    [@visibilityToggle]="element === expandedElementDisclosure && i === currentExpandedDisclosureIndex ? 'visible' : 'hidden'"
                                >
                                    <app-member-disclosure
                                        *ngIf="element.sponsorIdNo === null"
                                        [memberId]="element.id"
                                        [disclosure]="disclosureList"
                                        (disclosureStats)="setDisclosureStats($event)"
                                      >
                                    </app-member-disclosure>
                                </div>
                            </td>
                          </tr>
                      
                          <tr>
                            <td class="collapsed-td"
                            [attr.colspan]="columnsToDisplayNames.length"
                            [class.hidden-tr]="expandedElementMember !== element" >
                                <div  
                                  [@detailExpandMember]="(element === expandedElementMember ) ? 'expanded' : 'collapsed'"
                                  [@visibilityToggle]="element === expandedElementMember  ? 'visible' : 'hidden'"
                                >
                                  <app-member-data-row *ngIf="element.sponsorIdNo===null && getDependentsForSponsorMember(element).data.length>0" 
                                    [dataSource]="getDependentsForSponsorMember(element)" 
                                    [disclosureList]="disclosureList"
                                    (editMemberAction)="editMember($event)"
                                    (deleteMemberAction)="deleteMember($event)"
                                    (cancelMemberAction)="cancelActionMember()" >
                                  </app-member-data-row>
                              
                                <!--sub memener--> 
                              </div>
                            </td> 
                          </tr>

                        </ng-container>

                    </tbody>
                  </table>


                  <!-- Add Pagination at the bottom of the table -->
                  <nav *ngIf="totalPages > 1" aria-label="Page navigation example" class="pagination-nav">
                    <ul class="pagination justify-content-center">
                      <li class="page-item" [class.disabled]="currentPage === 1">
                        <a class="page-link border-1 rounded" (click)="goToPage(1)" aria-label="First">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" [class.disabled]="currentPage === 1">
                        <a class="page-link" (click)="previousPage()">السابق</a>
                      </li>

                      <li class="page-item" *ngFor="let page of pagesArray" [class.active]="page === currentPage">
                        <a class="page-link" (click)="goToPage(page)">{{ page }}</a>
                      </li>

                      <li class="page-item" [class.disabled]="currentPage === totalPages">
                        <a class="page-link" (click)="nextPage()">التالي</a>
                      </li>
                      <li class="page-item" [class.disabled]="currentPage === totalPages">
                        <a class="page-link" (click)="goToPage(totalPages)" aria-label="Last">
                          <span aria-hidden="true">&raquo;</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                    
                </div>
            </div> <!--end bootstrap table-->
            
        </div>
      </div><!--end col-->
     
        
      
      <!--*************************** File block ***************************-->  
      <div id="use-excel">
        <div class="file-section" style="display: block" *ngIf="memberDataList != undefined && memberDataList.length==0">
          <!-- Guide block - Use Form -->  

            <!-- upload block -->
            <div class="blue-box" id="howtouse-landing" style="margin-top: 25px;" >

              <div class="row align-items-center justify-content-between ">
                <div class="col-md-12">
                  <h5   class="bg-soft-info p-lg-3 p-4" style="margin-bottom: 20px;">
                    الرجاء اضافة المؤمن لهم عن طريق إضافة مؤمن له جديد أو عن طريق ملف اكسل!
                  </h5>
                </div>
                <!--member file upload -->
                <app-member-file ></app-member-file>
                <!-- end member file upload-->
              </div>

              <!-- Guide block - General rules -->  
              <div class="small orange-box bg-soft-warning p-lg-2 p-2" style="margin-top: 30px;">
                  <div class="cchi-min-count-message" style="display:none" id="cchi-count-info">
                      وفقًا لمجلس التأمين الصحي التعاوني ، الحد الأدنى لعدد الموظفين والمعالين الذين يجب إضافتهم هو &nbsp; (<span class="min-count" id="cchiminemp"></span>) &nbsp; موظفين &nbsp;
                      (<span class="min-count" id="cchimindep"></span>) &nbsp; تابعين &nbsp; ومع ذلك، يمكنك متابعة طلبك لمعاينة عرض الأسعار.
                  </div>
                  <div > 
                      <h6>قبل التقديم عليك القيام بما يلي:</h6>
                      <ul class="meduim">
                          <li> تأكد من أن الشركة لديها عنوان وطني مسجل في البريد السعودي</li>
                          <li> تأكد من إدخال هوية الكفيل للمؤمن له بالطريقة الصحيحة (للمُعالين)</li>
                          <li> تأكد من متابعة نفس القيم كما في القالب</li>
                      </ul>
                  </div>
              </div>

              <div class="page-loader" style="display: none;">
                  <img src="/assets/temp-template/member/images/blue-loader-animation.svg">
              </div>
            </div>
        </div>  

      </div>    

    </div>
  </div>

</div>
          