import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute, Routes } from '@angular/router';
import { MemberService } from '../../services/member.service';
import { DatePipe } from '@angular/common';
import { SessionStorageService } from '../../../shared/services/session-storage.service';
import { MemberDataList } from '../../models/member-data';
import { SharedDataService } from 'src/app/shared/services/shared-data.service';
import { Subject, Subscription } from 'rxjs';
import { PubSubService } from 'src/app/shared/services/pub-sub.service';
import { DisclosureService } from '../../services/disclosure.service';
import { Disclosure } from '../../models/disclosure';
import { MemberDisclosureComponent } from '../member-disclosure/member-disclosure.component';
import { MemberDisclosure } from '../../models/member-disclosure';
import { SmeDataComponent } from 'src/app/sme/sme-data/sme-data.component';
import { StepTabChildInterface } from 'src/app/shared/models/step-tab-child';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MemberComponent } from '../member.component';
import { MemberDataRowComponent } from '../member-data-row/member-data-row.component';
import { MemberTableUtilityService } from '../../services/member-table-utility-service';
import { Relation, YakeenCheckStatus } from '../../models/member';


@Component({
  selector: 'app-member-data',
  templateUrl: './member-data.component.html',
  styleUrls: [
     './member-data.component.scss', './table.component.scss' , './thead.component.scss'
  ],
  animations: [
    // Using the animation methods from the service
    MemberTableUtilityService.prototype.getDetailExpandAnimation(),
    MemberTableUtilityService.prototype.getDetailExpandMemberAnimation(),
    MemberTableUtilityService.prototype.getVisibilityToggleAnimation(),
  ]
})

/**
 * 
@TODO : Hilight member row on action (edit/add Dependent or remove)
@TODO : Add Cancel action in member row to cancel action (edit/add Dependent or remove)
@TODO : Filter : add menu (All/Dependents) at left of the filter input to spcifiy search target
@TODO : make sure to diable fields in edit mode : idNo + sponsorIdNo (for dependent case) + switch between Employee/Dependent
@TODO : Reset temporary disclosures (set by user but not yet saved) after refresh table caused by search input 

*/

export class MemberDataComponent implements StepTabChildInterface, OnInit, OnDestroy, AfterViewInit  {

  // Member Datatable
  columnsToDisplayNames: any[] = [];
  dataSource!: MatTableDataSource<any>;
  expandedElementDisclosure: any | null = null;
  expandedElementMember: any | null = null;
  
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  // Init data
  openForm!: boolean; // Define the property to hold the value
  private refreshSubscription!: Subscription;
  memberDataList!: MemberDataList[]; 
  disclosureList!: Disclosure[];
  allMemberDisclosures!: { [memberId: number]: MemberDisclosure[] };
  
  //Filter data
  filteredChildData!: any[];
  searchInput!: string;
  hide!: boolean;
  
  // member actions
  memberOnAction?: any;

  currentPage = 1; // Default to the first page
  pageSize = 5; // Number of rows per page
  paginatedData: any[] = []; // Data to display in the current page
  totalPages: number = 0;
  pagesArray: number[] = [];
 

  @ViewChild(SmeDataComponent, { static: false }) smeDataComponent!: SmeDataComponent;
  @ViewChildren(MemberDisclosureComponent) memberDisclosureComponents!: QueryList<MemberDisclosureComponent>;
  @ViewChild(MemberComponent) memberComponent!: MemberComponent;
  @ViewChild(MemberDataRowComponent) DependentMemberComponent!: MemberDataRowComponent;

  private memberDataUpdated$: Subject<any> = new Subject<any>();


  constructor(private memberService: MemberService, 
    private disclosureService: DisclosureService,  
    private datePipe: DatePipe, 
    private cdr: ChangeDetectorRef, 
    private sessionStorageService: SessionStorageService, 
    private router: Router, 
    private route: ActivatedRoute, 
    private sharedDataService: SharedDataService, 
    private refreshService: PubSubService,
    private memberTableUtilityService: MemberTableUtilityService
  ){}

 
  ngOnInit(){

    // Get columns from service
    this.columnsToDisplayNames = this.memberTableUtilityService.getColumnsConfig();
    
    const smeId = this.sessionStorageService.getItem('smeId');
    const smeUserId = this.sessionStorageService.getItem('smeUserId');
    
    this.getMemberData(smeUserId);
    this.getDisclosures();
    this.setDisclosureStats;
   
    // On new member add
    this.refreshSubscription = this.refreshService.refresh$.subscribe(() => {
      // Code to refresh the component, for example:
      this.getMemberData(smeUserId); // Call a method to fetch updated data
    });

    // Subscribe to the updated data
    this.memberDataUpdated$.subscribe(data => {
      this.memberDataList = data;
    });

    this.sharedDataService.setInSmeData(false);
  }
  
  ngAfterViewInit() {
  
    this.openForm = false; // Define the property to hold the value
    //this.memberDataList = [];
    this.disclosureList = [];
    this.allMemberDisclosures = {};
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  setDisclosureStats(event: any) {
    this.dataSource = this.memberTableUtilityService.setDisclosureStats(event, this.dataSource);
  }

  isCollapsed = true; // Initially collapsed
  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  setupPagination() {
    this.totalPages = Math.ceil(this.dataSource.filteredData.length / this.pageSize);
    this.pagesArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
    this.paginateData();
  }

  paginateData() {
    // Filter out sub-members (only paginate parent/kafil members)
    const parentMembers = this.dataSource.filteredData.filter((member) => member.sponsorIdNo === null);
  
    // Determine the start and end indexes based on the current page and page size
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = startIndex + this.pageSize;
  
    // Slice the data for the current page (only paginating parent members)
    this.paginatedData = parentMembers.slice(startIndex, endIndex);
  
    // Update the pagination variables in a way that takes only parent (kafil) member
    this.totalPages = Math.ceil(parentMembers.length / this.pageSize);
    this.pagesArray = Array.from({ length: this.totalPages }, (_, i) => i + 1);
  }
  
  
  // Go to a specific page
  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.paginateData();
    }
  }

  // Go to the next page
  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.paginateData();
    }
  }

  // Go to the previous page
  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
      this.paginateData();
    }
  }

  initMemberDataTable(): void{
    
    // Make sure that all element of this.memberDataList are unique based on idNo
    // Create a Map to track unique items based on idNo
    const uniqueMap = new Map<number, any>();
    this.memberDataList.forEach((item) => {
      const idNo = parseInt(item.idNo, 10);// @TODO : make sure the final idNo type to choose in the MemberDataList (number/string)
      uniqueMap.set(idNo, item);
    });
    this.memberDataList = Array.from(uniqueMap.values());
    
    // Generate dataSource form memberDataList
    this.dataSource = new MatTableDataSource(this.memberDataList);
    this.dataSource.paginator = this.paginator;

    console.log("this.memberDataList ==> ", this.memberDataList);

    //this.dataSource.sort = this.sort;

    // Init dependents memberDataList
    this.filteredChildData = this.memberDataList
      .map((element) => this.getDependentsForSponsorMember(element, true).data)
      .flat();

    // Init expanded blocs (Dependent and Disclosure)
    this.expandedElementMember=null;
    this.expandedElementDisclosure=null;
    
  }

  clearFilter() {
    this.searchInput = '';
    this.applyFilter(this.searchInput);
    this.setupPagination();
  }

  applyFilter(filterValue: string) {
    this.goToPage(1);
    this.expandedElementMember = true;
    filterValue = filterValue.trim().toLowerCase();
    
    // Reset the table data to the full dataset before filtering
    this.initMemberDataTable();
  
    // Check if the filter value is empty, if so, reset pagination and show all data
    if (filterValue === '') {
      this.paginateData(); 
      return;
    }
  
    // Filter parent data (only filter based on parent members, not sub-members)
    let filteredParentData = this.memberDataList.filter(element => {
      if (element.sponsorIdNo === null) {
        return this.checkFilterValueInElement(element, filterValue);
      }
      return false;
    });
  
    // Filter child data (dependents)
    this.filteredChildData = this.filteredChildData.filter(childElement => {
      return this.checkFilterValueInElement(childElement, filterValue);
    });
  
    // If the filter value matches dependents and is only for one sponsor, expand the sponsor's panel
    const uniqueSponsorIds = Array.from(new Set(this.filteredChildData.map(element => element.sponsorIdNo)));
    
    const isSameSponsor = uniqueSponsorIds.length === 1;
    if (isSameSponsor) {
      const sponsorId = uniqueSponsorIds[0]; 
      const sponsorMember = this.memberDataList.find(member => member.idNo === sponsorId);
      
      if (sponsorMember) {
        this.toggleExpandedElementMember(sponsorMember, this.currentExpandedDisclosureIndex);
      } 

    } else {
      this.toggleExpandedElementMember(this.expandedElementMember, null);        
    }
  
    // Case 1: If the filter value is found in filteredChildData, ensure parent records are present
    this.filteredChildData.forEach((element) => {
      const isPresentInParent = filteredParentData.some(parentElement => parentElement.idNo === element.idNo);
      if (!isPresentInParent) {
        const filteredSponsorForDependent = this.getSponsorForDependentMember(element);
        if (filteredSponsorForDependent.idNo === element.sponsorIdNo) {
          this.filteredChildData = this.filteredChildData.concat(filteredSponsorForDependent);
        }
      }
    });
  
    // Case 2: If the filter value is found in filteredParentData, ensure child data is included
    filteredParentData.forEach((element) => {
      const isPresentInParent = this.filteredChildData.some(childElement => childElement.idNo === element.idNo);
      if (!isPresentInParent) {
        const dependentsForSponsor = this.getDependentsForSponsorMember(element, true).data;
        this.filteredChildData = this.filteredChildData.concat(dependentsForSponsor);
      }
    });
  
    // Combine parent and child filtered data
    const combinedFilteredData = [...filteredParentData, ...this.filteredChildData];
  
    // Remove duplicates based on 'idNo' (adjust this if your unique identifier is different)
    const uniqueCombinedFilteredData = Array.from(
      combinedFilteredData.reduce((map, item) => map.set(item.idNo, item), new Map()).values()
    );
  
    // Sort the filtered data by 'idNo' (or another field if needed)
    const sortedUniqueCombinedData = uniqueCombinedFilteredData.sort((a:any, b:any) => {
      return a.idNo - b.idNo;
    });
  
    // Assign the sorted and filtered data to the dataSource and reset pagination
    this.dataSource = new MatTableDataSource(sortedUniqueCombinedData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  
    // Reset the pagination to show the first page of filtered results
    this.paginateData();
    
    // Move to the first page if the paginator is present
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  checkFilterValueInElement(element: any, filterValue: string): boolean{
    
      // Example filter for 'name' and 'idNo' fields
      const nameMatch = element.name.toLowerCase().includes(filterValue);
      const idMatch = element.idNo.toString().includes(filterValue);
      return nameMatch || idMatch || element.idNo.toString() === filterValue;
      // Add more conditions as needed for exact matches
      // @TODO : Complete the matching logic for the rest of MemberDataList properties
  }

  getMemberData(smeUserId: string) {
    this.memberService.findMemberBySmeUserId(smeUserId).subscribe({
      next: (data) => {
        this.memberDataUpdated$.next(data);
        this.calculateDependentsCount();
        this.initMemberDataTable();
        this.memberDataReadyToNexStep();
        this.setupPagination();
      },
      error: (e) => console.error(e)
    });
  }

  calculateDependentsCount(): void {
    this.memberDataList.forEach(member => {
      if (member.sponsorIdNo === null) {
        const dependents = this.memberDataList.filter(dependent => dependent.sponsorIdNo === member.idNo);
        member.dependentsCount = dependents.length;
      }
    });
  }

  getDependentsForSponsorMember(sponsorMember: any, inputfilterAction: boolean = false): MatTableDataSource<any> {
    // Rule : 
    // Search in this.memberDataList all members (objects) having sponsorIdNo = memberId
    // Filter members where sponsorIdNo matches memberId
    if(inputfilterAction){ // Case of general filter from search input
      
      if(sponsorMember.sponsorIdNo === null){ // Make sure that is really a dependents (rule : only dependents have filled sponsorIdNo)
        const dependentsDataList = this.memberDataList.filter(member => member.sponsorIdNo === sponsorMember.idNo);
        // Create a new MatTableDataSource from the filtered list
        const dataSource = new MatTableDataSource<any>(dependentsDataList);
        return dataSource; 
      }
      
    } else { // Case of standard filter from <app-member-data-row>
      // Create a new MatTableDataSource from the filtered list
      const dependentsDataList = this.filteredChildData.filter(member => member.sponsorIdNo === sponsorMember.idNo);
      const dataSource = new MatTableDataSource<any>(dependentsDataList);
      
      return dataSource;
    }

    return new MatTableDataSource<any>();

  }

  getSponsorForDependentMember(dependentMember: any): any {
    // Rule : 
    // Search in this.memberDataList all sponsor members (objects) having idNo = dependentMember.sponsorIdNo
    // Filter dependents members where sponsorIdNo matches sponsor IdNo 
    if(dependentMember.sponsorIdNo !== null){ // Make sure that is really a dependents (rule : dependents only have filled sponsorIdNo)
      const sponsorData = this.memberDataList.find(member => dependentMember.sponsorIdNo === member.idNo);
        // Create a new MatTableDataSource from the filtered list
        if (sponsorData) {
          return sponsorData;
        } 
    } 
    return {} as any;
  }

  getDisclosures(): void {
    
    this.disclosureService.getDisclosureList().subscribe({
      next: (data) => {
        this.disclosureList = data;
     
        // Redirect to the 'sme' route with the SME's ID or any relevant information
        //this.router.navigate(['/sme', data.id]); // Adjust as per your data structure
      },
      error: (e) => console.error(e)
    });
  }

  saveMemberDisclosures(): void {
    
    // Clear allMemberDisclosures
    this.allMemberDisclosures = {};

    if (this.memberDisclosureComponents) {
      this.memberDisclosureComponents.forEach(component => {
        const memberId = component.memberId;
        if (!this.allMemberDisclosures[memberId]) {
          this.allMemberDisclosures[memberId] = [];
        }
        this.allMemberDisclosures[memberId].push(...component.memberDisclosure);
      });


      // Append dependents disclosure
      if(this.DependentMemberComponent!=undefined){
        const allDependentDisclosures = this.DependentMemberComponent.getAllDependentDisclosures();
        // combine the this.allMemberDisclosures with the : allDependentDisclosures
        this.allMemberDisclosures = Object.assign({}, this.allMemberDisclosures, allDependentDisclosures); 
      }
      

      // Call the service method to add all member disclosures
      this.disclosureService.saveAllMemberDisclosures(this.allMemberDisclosures).subscribe({
        next: (res) => {
          console.log(`Post memberDisclosures :`, res);
        },
        error: (e) => console.error(e)
      });
      
    }
  }

  handleOpenFormChange(value: boolean): void {
    this.openForm = value; // Update the value received from the child component
  }

  // Toogles : Disclosures / Member dependents
  //expandedElementDisclosure: any = null;
  currentExpandedDisclosureIndex: number | null = -1;
  
  // Function to toggle expandedElementDisclosure
  isclosing=false;
  toggleExpandedDisclosure(element: any, index: number) {
      if (this.expandedElementDisclosure === element) {
        this.isclosing=true;
        setTimeout(() => {
          this.isclosing=false;
          this.currentExpandedDisclosureIndex = -1;
          this.expandedElementDisclosure = null; // Close if already open
        }, 600);
          
          
      } else {
          this.isclosing= false;
          this.isclosingMember=false;
          this.expandedElementDisclosure = element; // Open the selected element
          this.currentExpandedDisclosureIndex = index;
          this.expandedElementMember= null;
      }
  }

  isclosingMember=false;
  toggleExpandedElementMember(elementMember: any | null , index:number | null): void {
    if (this.expandedElementMember === elementMember) {
      
      this.isclosingMember=true;
      setTimeout(() => {
        this.isclosingMember=false;
        this.currentExpandedDisclosureIndex = -1;
        this.expandedElementMember = null;
      }, 400);

    }else{

      this.isclosing= false;
      // this.expandedElementMember = this.expandedElementMember === elementMember ? null : elementMember;
      setTimeout(() => {
        this.isclosingMember=false;
        this.expandedElementMember = elementMember;
        this.currentExpandedDisclosureIndex = index;
        this.expandedElementDisclosure = null;
      }, 400);
      
    }
    
  }

  addDependentMember(sponsorMember: any): void{
    this.memberOnAction = sponsorMember;
    this.memberComponent.member.relation = Relation.DEPENDENT;
    this.memberComponent.member.sponsorIdNo = sponsorMember.idNo;
    this.memberComponent.parentMemberDataListToSelect2SelectedOption = sponsorMember.idNo;
    this.memberComponent.member.insClass = sponsorMember.insClass;
    this.memberComponent.handleSelection();
    this.memberComponent.newMember();

  }

  editMember(member: any): void{
    this.memberOnAction = member;
    this.memberComponent.editMemberMode = true;
    this.memberComponent.member = member;
    this.memberComponent.parentMemberDataListToSelect2SelectedOption = member.sponsorIdNo;
    this.memberComponent.handleSelection();
  }

  deleteMember(member: any): void{
    this.memberOnAction = member;
    this.memberComponent.deleteMemberMode = true;
    this.memberComponent.member = member;
    this.memberComponent.parentMemberDataListToSelect2SelectedOption = member.sponsorIdNo;
  }

  cancelActionMember(): void{
    this.memberOnAction = undefined;    
    this.memberComponent.clearMemberAction();
  }

  // Method triggered when a member is added in the child component
  onMemberAdd(newMember: any) {
    
    setTimeout(() => {

      console.log("newMember ==>", newMember);

        // Refresh the member data here or perform actions related to the new member addition
      // Trigger the animation on the last added row by setting an animate property or class
      const lastAddedMember = this.memberDataList.find((member) => member.idNo === newMember.idNo);
      console.log("lastAddedMember ==>", lastAddedMember);
      
      const newMemberIsDependent = (lastAddedMember?.sponsorIdNo !== null) ? true : false;
      
      let memberIdNo;
      let expandedElementMember;
      let sponsorOfLastAddedMember: MemberDataList | any;

      if(newMemberIsDependent){
        console.log('newMemberIsDependent ');
        //sponsorOfLastAddedMember = this.memberDataList.find(sponsor => sponsor.idNo === newMember.sponsorIdNo);
        memberIdNo = newMember?.idNo;
        expandedElementMember = lastAddedMember;
      } else {
        sponsorOfLastAddedMember = lastAddedMember;
        memberIdNo = lastAddedMember?.idNo;
        expandedElementMember = lastAddedMember;
      }
      
      this.searchInput = (memberIdNo) ? memberIdNo : this.searchInput;
      this.applyFilter(this.searchInput);
      console.log('sponsorOfLastAddedMember ==> ', sponsorOfLastAddedMember);
      console.log('this.expandedElementMember ==> ', this.expandedElementMember);
      this.expandedElementMember = (sponsorOfLastAddedMember) ? sponsorOfLastAddedMember : this.expandedElementMember;
      console.log('this.expandedElementMember ==>', this.expandedElementMember);
      
      this.dataSource.data.forEach((member: any) => {
      if (member.idNo === lastAddedMember?.idNo) {
          
          member.animate = true;
          
          // Optionally, remove the animation after a certain period
          setTimeout(() => {
            member.animate = false;
          }, 2000); // Remove the animation after 1 second (adjust timing as needed)
        }
      });

    }, 500);
  }

  trans(memberAttribute: string): any{
    return this.memberTableUtilityService.trans(memberAttribute); 
  }

  getImgDir(nationality: string): string {
    return this.memberTableUtilityService.getImgDir(nationality); 
  }

  memberDataReadyToNexStep(){
    // verify that all acceptance criteria are filled
    // Add some controls here
    const isReady = (this.memberDataList.length>0);
    this.refreshService.acceptOrderAction(isReady);
  }

  isValidMemberStatus(){
    return this.memberTableUtilityService.isValidMemberStatus(); 
  }

  saveAllOnNextAction(): void {
    this.saveMemberDisclosures();
    this.smeDataComponent.saveAllOnNextAction();
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
}

