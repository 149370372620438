import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StepTabChildInterface } from 'src/app/shared/models/step-tab-child';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../../services/order-service';
import { Order } from '../../models/order';
import { OrderSummary } from '../../models/order-summary';
import { KeycloakService } from 'keycloak-angular';
import { PubSubService } from 'src/app/shared/services/pub-sub.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { PaymentBrand } from '../../models/payment-brand';
import { InsCompany } from 'src/app/quotation/models/ins-company';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements StepTabChildInterface, OnInit, OnDestroy {

  @ViewChild('iAgreeOnTermsAndConditions') iAgreeOnTermsAndConditions!: ElementRef;
  @ViewChild('iConfirmMedicalDisclosure') iConfirmMedicalDisclosure!: ElementRef;

  showOrderDetails!:boolean;
  selectedQuotationId!: number;
  selectedPaymentBrand!: number;
  orderId!: number;
  paymentBrand!: PaymentBrand[];
  orderSummary!: OrderSummary;
  order!: Order;

  // Stats data
  smeCrName!: string;
  smeCrNumber!: number;
  smeCrEntityNumber!: number;
  newPolicyStartDate!: string;
  totalMemberCount!: number;
  sponsorsMemberCount!: number;
  dependentsMemberCount!: number;
  insClassCount!: number;
  insClassData!: any;
  quotationId!: number;
  insCompanyName!: string;
  insCompanyNameWebsite!: string;
  quotationBeforeVat!: number;
  quotationVatAmount!: number;
  quotationPrice!: number;
  quotationInsCompanyData!: InsCompany;
  quotationInsCompanyCode!: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private orderService: OrderService,
    private readonly keycloak: KeycloakService,
    private pubSubService: PubSubService,
    private commonService: CommonService,
    private spinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {

    this.showOrderDetails=false;

    // Set loading to true to show the loader
    this.spinnerService.show();

      if (this.keycloak.isLoggedIn()) {
        this.route.params.subscribe(params => {
          this.selectedQuotationId = params['qId'];
          this.prepareOrder();
          this.getPaymentBrand();

        });
      } else {
        // User is not logged in or login failed
        this.commonService.login();
      }
  }

  isCollapsed = false; // Initially collapsed
  isCollapsed1 = false; // Initially collapsed
  isCollapsed2 = false; // Initially collapsed
  isCollapsed3 = true; // Initially collapsed
  isCollapsed4 = false; // Initially collapsed
  isCollapsed5 = false; // Initially collapsed
  isCollapsed6 = false;
  toggleCollapse(paramName: string, paramValue: boolean){
    (this as any)[paramName] = this.commonService.toggleCollapse(paramValue);
  }


  prepareOrder(): void{
    this.orderService.getOrderSummary({'quotationId': this.selectedQuotationId}).subscribe({
      next: (data) => {
        this.orderSummary = data;
        this.initData();

        // Set loading to true to show the loader
        this.showOrderDetails = true;
        this.spinnerService.hide();
      }
    })
  }

  // Function to get the count of insClasses
  initData(): void {
    if(this.orderSummary != undefined){
      this.smeCrName = this.orderSummary.smeUserSummary.smeCrName;
      this.smeCrNumber = this.orderSummary.smeUserSummary.smeCrNumber;
      this.smeCrEntityNumber = this.orderSummary.smeUserSummary.smeCrEntityNumber;

      this.newPolicyStartDate = this.orderSummary.smeUserSummary.newPolicyStartDate;
      this.totalMemberCount = (+this.orderSummary.smeUserSummary.sponsorsMemberCount) +  (+this.orderSummary.smeUserSummary.dependentsMemberCount);
      this.sponsorsMemberCount = this.orderSummary.smeUserSummary.sponsorsMemberCount;
      this.dependentsMemberCount = this.orderSummary.smeUserSummary.dependentsMemberCount;
      this.insClassCount = Object.keys(this.orderSummary.smeUserSummary.insClassCount || {}).length;
      this.insClassData = this.orderSummary.smeUserSummary.insClassCount;
      this.quotationId = this.orderSummary.quotation.id;
      this.insCompanyName = this.orderSummary.quotation.insCompany.name;
      this.insCompanyNameWebsite = this.orderSummary.quotation.insCompany.website;
      this.quotationBeforeVat = this.orderSummary.quotation.priceBeforeVat;
      this.quotationVatAmount = this.orderSummary.quotation.vatAmount;
      this.quotationPrice = this.orderSummary.quotation.price;
      this.quotationInsCompanyData = this.orderSummary.quotation.insCompany;
      this.quotationInsCompanyCode = this.quotationInsCompanyData.code;
    }
  }

  // This use await instead of Observable to force awainting the response of createOrder (and get the orderId) before passing to nextStep with orderId as route parameter
  async createOrder(): Promise<void>{

    try {
      const data = await this.orderService.createOrder({'quotationId': this.selectedQuotationId, 'paymentBrandId': this.selectedPaymentBrand});
      this.order = data;
      this.orderId = this.order.id;
    } catch (error) {
      // Handle errors here
      console.error('Error:', error);
    }
  }

  acceptOrder(){

    // verify that all acceptance checkbox is checked
    const isIAgreeOnTermsChecked: boolean = this.iAgreeOnTermsAndConditions.nativeElement.checked;
    const isHDFDeclarationChecked: boolean = this.iConfirmMedicalDisclosure.nativeElement.checked;

    const isChecked: boolean = isIAgreeOnTermsChecked && isHDFDeclarationChecked;
    this.pubSubService.acceptOrderAction(isChecked);
  }

  selectPaymentBrand(paymentBrandId?: number): void{
    let selectBrandIndex = this.paymentBrand.findIndex((brand) => brand.isDefault === true);
    if(paymentBrandId !== undefined && !isNaN(paymentBrandId)){
      selectBrandIndex = this.paymentBrand.findIndex((brand) => brand.id == paymentBrandId);
    }

    // UnSelect all
    this.paymentBrand.map((brand) => {
      return brand.isDefault = false;
    })
    // Select the new
    this.paymentBrand[selectBrandIndex].isDefault = true;
    this.selectedPaymentBrand = this.paymentBrand[selectBrandIndex].id;
    console.log('selectedPaymentBrand ==>', this.selectedPaymentBrand);
  }

  async saveAllOnNextAction(): Promise<void | any> {

    await this.createOrder();
    return {
      "keyParameter": this.orderId
    };
  }

  getPaymentBrand(): void{
    this.orderService.getPaymentBrand().subscribe({
      next: (data: PaymentBrand[]) => {
        this.paymentBrand = data;
        console.log("data ====>",this.paymentBrand)

        this.selectPaymentBrand();
      }
    })
  }



  ngOnDestroy(): void {

  }


}
