 <div class="add-member-container  border-1 fade-in">
    <div class="add-member-header" >
      <span class="header-content">
        <i class="mdi mdi-account-cog"></i>
        <span class="header-content-title">
          إدارة تفاصيل الأعضاء
        </span>
        </span>
    </div>
    <div class="add-member-body border-1  bg-light">
      <form>
          <!-- نوع المؤمن له -->
            <label>
              اختر نوع المؤمن له 
            </label>
            <div class="form-row custom-radio-group radio-group switched mb-3 nav nav-pills nav-justified 
            flex-column flex-sm-row rounded bg-light wow animate__animated animate__fadeInUp" data-wow-delay=".3s" id="pills-tab" role="tablist">
              <div class="nav-item">
                <input class="form-check-input nav-link rounded active"
                       type="radio" 
                       name="relation" 
                       id="memberType_1"
                       [value]="Relation.EMPLOYEE"
                       [(ngModel)]="member.relation"
                       [checked]="member.relation==Relation.EMPLOYEE" 
                       [disabled]="deleteMemberMode || editMemberMode" 
                       (change)="handleSelection()"
                        data-bs-toggle="pill" href="#pills-teamwork" 
                        role="tab" 
                        aria-controls="pills-teamwork" 
                        aria-selected="false">
                <h6 class="mb-0">موظف</h6>
              </div><!--end nav item-->
            
              <div class="nav-item">
                <input class="form-check-input nav-link rounded" 
                        type="radio" 
                        name="relation" 
                        id="memberType_2" 
                        [value]="Relation.DEPENDENT"
                        [(ngModel)]="member.relation" 
                        [checked]="member.relation==Relation.DEPENDENT" 
                        [disabled]="deleteMemberMode || editMemberMode" 
                        (change)="handleSelection()" 
                        data-bs-toggle="pill" 
                        href="#pills-teamwork"
                        role="tab" 
                        aria-controls="pills-teamwork"
                        aria-selected="false">
                <h6 class="mb-0">تابع</h6>
              </div><!--end nav item-->
            </div><!--end nav pills-->

          <!-- رقم الهوية -->
          <div class="form-row">
            <label>رقم الهوية</label>
            <div class="form-icon">
              <input
                [(ngModel)]="member.idNo"
                (ngModelChange)="handleSelection()"
                [disabled]="deleteMemberMode || editMemberMode"
                class="form-control ps-5"
                #idNoInput
                placeholder="EX.: 00-0000-0000"
                type="text"
                value=""
                name="IDNumber"
              />
              <span class="svg-icon">
                <svg
                  width="32px"
                  height="32px"
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="#b3b3b4"
                    d="M 5 6 C 3.355469 6 2 7.355469 2 9 L 2 23 C 2 24.644531 3.355469 26 5 26 L 27 26 C 28.644531 26 30 24.644531 30 23 L 30 9 C 30 7.355469 28.644531 6 27 6 Z M 5 8 L 27 8 C 27.566406 8 28 8.433594 28 9 L 28 23 C 28 23.566406 27.566406 24 27 24 L 5 24 C 4.433594 24 4 23.566406 4 23 L 4 9 C 4 8.433594 4.433594 8 5 8 Z M 11 10 C 8.800781 10 7 11.800781 7 14 C 7 15.113281 7.476563 16.117188 8.21875 16.84375 C 6.886719 17.746094 6 19.28125 6 21 L 8 21 C 8 19.332031 9.332031 18 11 18 C 12.667969 18 14 19.332031 14 21 L 16 21 C 16 19.28125 15.113281 17.746094 13.78125 16.84375 C 14.523438 16.117188 15 15.113281 15 14 C 15 11.800781 13.199219 10 11 10 Z M 18 11 L 18 13 L 26 13 L 26 11 Z M 11 12 C 12.117188 12 13 12.882813 13 14 C 13 15.117188 12.117188 16 11 16 C 9.882813 16 9 15.117188 9 14 C 9 12.882813 9.882813 12 11 12 Z M 18 15 L 18 17 L 26 17 L 26 15 Z M 18 19 L 18 21 L 23 21 L 23 19 Z"
                  ></path>
                </svg>
              </span>
            </div>
            
          </div>
          <!-- رقم الكفيل -->
          <div
            *ngIf="member.relation==Relation.DEPENDENT"
            id="sponsor-div"
            class="form-row" style="margin-top: 20px;"
          >
            <label>رقم الكفيل </label>
              <select2
                  [data]="parentMemberDataListToSelect2Data"
                  [value]="parentMemberDataListToSelect2SelectedOption"
                  [templates]="template"
                  minCountForSearch="1"
                  id="selec2-sponsor"
                  class="select2"
                  [(ngModel)]="member.sponsorIdNo"
                  [ngModelOptions]="{standalone: true}"
                  [disabled]="deleteMemberMode || editMemberMode"
              >
                  <ng-template #template let-data="data">
                      
                      <div class="select2-multiple-labels">
                          <span>{{ data.idNo }}</span>
                          <span> - </span>
                          <span>{{ data.name }}</span>
                          
                          <a class="badge insClass-badge float-end lh-small col-2 shadow-sm" 
                            [ngClass]="{'bg-soft-success': data.insClass=='VIP', 'bg-secondary': data.insClass!='VIP'}">
                            <span class="ins-badge-conent">{{data.insClass}}</span>
                          </a>
                      </div>
                      
                  </ng-template>
                  
              </select2>
          </div>
          
        <!-- تاريخ الميلاد -->
          <div
            id="dob-section"
            class="form-row form-date"
            *ngIf="memberNeedDobPrecision"
          >
            <label>تاريخ الميلاد</label>
            <div class="row">
              <div class="col-6">
                <div class="edit-field w-100">
                  <select2 
                  [data]="hijriMonthsFormemberBirthDateSelect2" displaySearchStatus="hidden"
                  [value]="hijriMonthsFormemberBirthDateSelect2SelectedOption"
                  (update)="onMonthUpdate($event)"
                  placeholder="اختر الشهر"
                  ></select2>
                 </div>
              </div>
              <div class="col-6">
                <div class="edit-field w-100">
                  <select2 
                  [data]="hijriYearsFormemberBirthDateSelect2"
                  [value]="hijriYearsFormemberBirthDateSelect2SelectedOption"
                  (update)="onYearUpdate($event)"
                  [overlay]="true"
                  placeholder="اختر السنة"
                  ></select2>
                 </div>
              </div>
            </div>
          </div>

          <!-- ? متزوج -->

          <div class="form-row">
            <div class="custom-checkbox">
              <div class="Form-section">
                <label class="Form-label-tick mb-0 d-inline-block"  >
                  <input 
                  
                    class="Form-label-checkbox" 
                    data-val="true" 
                    data-val-required="The IsMarried field is required." 
                    id="marital" 
                    name="marital" 
                    
                    type="checkbox"
                    [checked]="member.marital=='MARRIED'" 
                    
                    (click)="member.marital = (member.marital === 'MARRIED') ? 'SINGLE' : 'MARRIED'"
                    
                  >
                  <span class="Form-label-text mt-3"  >متزوج</span>
                </label>
              </div>
            </div>
          </div>
          <!-- فئة التأمين -->
          <div class="form-row ">
            <label>
              اختر صنف التأمين الطبي: 
            </label>
            <div class="form-row custom-radio-group radio-group switched mb-3
             nav nav-pills nav-justified flex-column flex-sm-row rounded bg-light"
              id="pills-tab" role="tablist" style="margin-bottom: 30px !important;">
              <div class="nav-item" *ngFor="let value of insClassValues">
                <input class="form-check-input2 nav-link rounded active" type="radio" 
                name="InsuranceClassID" id="InsuranceClassID_1" 
                [(ngModel)]="member.insClass"  [value]="value" value="value"
                 data-bs-toggle="pill" role="tab" aria-controls="pills-teamwork" aria-selected="false">
                <h5 class="mb-0">{{value}}</h5>
              </div>
            </div> 
          </div>

            <!-- رقم الجوال -->
            <div class="form-row" style="margin-top: -10px">
              <div class="d-flex justify-content-between align-items-center w-100">
                <label>رقم الجوال</label>
              </div>

              <input
                class="form-control"
                data-val="true"
                data-val-required="The MobileNumber field is required."
                id="mem_mobileNumber"
                maxlength="10"
                name="MobileNumber"
                placeholder="05XXXXXXXX"
                type="text"
                value=""
                [(ngModel)]="member.mobileNumber"
              />
              <span
                style="display: none"
                id="mobile_ValidationMsg"
                class="text-danger"
                >رقم الجوال يجب أن يكون بالصيغة التالية 05XXXXXXXX</span
              >
            </div>
            

          <!-- Controls -->
          <span style="display: none" id="existing_member" class="text-danger"
            >رقم هوية المؤمن له المدخل موجود مسبقاً في قائمة المؤمن لهم</span
          >

         <div class="button-container">
              <button href="javascript:void(0)" style="align: center;"
              class="btn btn-primary btn-add"
              (click)="(editMemberMode) ? editMember() : ((deleteMemberMode) ? deleteMember() : addMember())">
              {{(editMemberMode) ? 'حفظ التعديل' : ((deleteMemberMode) ? 'حذف العضو' : (memberGovApiResponseError ? 'نرجو التأكد من البيانات المدخلة' : 'حفظ وأضف المزيد') )  }}

              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
              </svg>
            </button>
         </div>
         

            
          
          <br/>
          <div #govApiResponseError [hidden]="!memberGovApiResponseError" style="font-size:12px">
            <div  class="text-danger"> الخطأ : {{memberGovApiResponseErrorTitle}}</div>
            <div  class="text-danger">التفاصيل : {{memberGovApiResponseErrorMessage}}</div>
          </div>
          
          
        
      </form>

    </div>
       
  
      <script type="text/javascript">
        var minJoinDate = "2023-06-24";
        var maxJoinDate = "2023-12-24";
      </script>
      <div id="filling-form-Fixing" style="display: none"></div>

  </div>
  