export interface Member{
    smeId: number,
    idNo: string | null; // or number | undefined;
    birthDate: string | null;
    hijriBirthDate: string | null;
    sponsorIdNo: number | null;
    mobileNumber: string;
    nationality: Nationality | null;
    name: string | null;
    gender: string;
    relation: Relation;
    dependentRelation: DependentRelation | null;
    marital: string;
    insClass: string;
    yakeenCheckStatus: YakeenCheckStatus; // Enum represented as a union of string literals;
    yakeenCheckError: MembersGovApiResponse
    
}

export interface MembersGovApiResponse{
    statusCode: number,
    errorTitle: string,
    errorMessage: string
}


export enum YakeenCheckStatus {
    DRAFT = 'DRAFT',
    FAILED = 'FAILED',
    REJECTED = 'REJECTED',
    VALIDATED = 'VALIDATED'
}

export enum Relation {
    EMPLOYEE = 'EMPLOYEE',
    DEPENDENT = 'DEPENDENT'
}

export enum DependentRelation {
    DEPENDENT = 'DEPENDENT', // default
    WIFE = 'WIFE',
    DAUGHTER = 'DAUGHTER',
    SON = 'SON'
}

export enum Nationality {
    SAUDI = 'SAUDI', // default
    NONSAUDI = 'NONSAUDI'
}
