import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { PubSubService } from '../../services/pub-sub.service';
import { KeycloakInstance } from 'keycloak-js';
import {  HostListener } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: [ './header.component.css'
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {


  private keycloakAuth: any = null;
  searchQuery = '';
  cartCount$ = new Observable<number>();
  destroySub = new Subject();
  isLoggedIn = false;
  userInformations: any;
  userName!: String;
  showToggleBox: boolean = false;
  isNavOpen = false;
  isScroll = false;

  // @ViewChild('loginModal') loginModal!: NgbModal;
  @ViewChild('keycloakIframe') keycloakIframe!: HTMLIFrameElement;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const header = document.getElementById('topnav');
    const a = document.querySelectorAll('#nav-item');

    if(this.isCurrentRouterHome){
      if (  (window.pageYOffset > header!.clientHeight)  ) {
        header!.classList.add('white-background');
        this.isScroll =true;
        a.forEach((element: any) => {
          element.classList.add('black-color');
        });
      } else {
        header!.classList.remove('white-background');
        this.isScroll = false;
        a.forEach((element: any) => {
          element.classList.remove('black-color');
        });
      }
    }

  }
  constructor(
        private readonly keycloak: KeycloakService,
        private router: Router,
        private readonly refreshService: PubSubService
        //private readonly modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.checkCurrentRouter();
    this.checkLoginStatus();
    this.keycloakAuth = this.keycloak.getKeycloakInstance();
    console.log("this.keycloakAuth ==>", this.keycloakAuth);
    // Check if the user is authenticated before making the request
   if (this.keycloakAuth.authenticated) {
    //if(this.keycloakAuth){
      // Get the token
      const token = this.keycloakAuth.token;
      this.isLoggedIn = true;
      console.log('ngOnInit HeaderComponent : this.keycloak.getToken() =>', token);
    }
    this.userInformations = this.isLoggedIn ? this.keycloakAuth.idTokenParsed : {};
    if(this.userInformations != undefined) {
      this.userName = this.userInformations.name;
    }
    console.log('userInformations =>', this.userInformations);
  }

  toggleBox() {
    this.showToggleBox = !this.showToggleBox;
  }
  isCurrentRouterHome=false;
  checkCurrentRouter(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url === '/home' || this.router.url === '/1') {
          this.isCurrentRouterHome= true;
          console.log("is current route is home ???", this.isCurrentRouterHome);
        }
        else{
          this.isCurrentRouterHome= false;
          const a = document.querySelectorAll('#nav-item');
          a.forEach((element: any) => {
            element.classList.add('black-color');
          });
        }
      }
    });
  }

  toggleNav() {
    this.isNavOpen = !this.isNavOpen;
  }

  closeNav() {
    this.isNavOpen = false;
  }

  public login() {
    const width = window.innerWidth * 0.3; // 20% of the current window's width
    const height = window.innerHeight * 0.8; // 80% of the current window's height

    // Calculate left and top position for centering the window
    const left = (screen.width - width) / 2;
    const top = (screen.height - height) / 2;

    // Open a new window with the route to LoginPopupComponent
    const newWindow = window.open('/auth', '_blank', `width=${width}, height=${height}, left=${left}, top=${top}, resizable=no`);

    // Focus the new window (optional)
    if (newWindow) {
      newWindow.focus();
    }
  }

  public logout() {
    this.keycloak.logout();
  }

  ManagedAccount() {
    this.keycloakAuth.accountManagement();
  }

  checkLoginStatus(): void {
    this.isLoggedIn = this.keycloak.isLoggedIn();
  }

  ngOnDestroy(): void {
    this.destroySub.next(true);
    this.destroySub.complete();

  }
}
